import {
    Backdrop,
} from '@mui/material';
import CircularProgress from '@mui/joy/CircularProgress';

interface LoaderProps {
    openLoader: boolean;
    inPage: boolean;
    redirect: boolean;
}
const Loader = (props: LoaderProps) => {
    return (
        <div>
            {
                props.inPage ?
                    <div className="loaderContent">
                        <CircularProgress variant="plain" size="lg"/>
                    </div>
                    :
                    props.redirect ?
                        <Backdrop
                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={props.openLoader}
                        >
                            <CircularProgress variant="plain" />
                        </Backdrop>
                        :
                        <Backdrop
                            sx={{ color: '#000', backgroundColor: '#ffffff' }}
                            open={props.openLoader}
                        >
                            <CircularProgress variant="plain" />
                        </Backdrop>
            }
        </div>
    )
}

export default Loader;