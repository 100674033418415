//Material ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle
} from "@mui/material";

//Estilo
import { useNavigate } from "react-router";

//Interface de la entrada del componente
interface PropsInterface {
    open: boolean
    text: string
    quantity: number
}
const DialogConfirmation = (props: PropsInterface) => {
    const navigate = useNavigate()

    const hiddenDialog = () => {
        navigate(props.quantity)
        return
    }

    return (
        <Dialog open={props.open}>
            <div style={{ width:"100%", textAlign:"center"}}>
                <div  style={{ width:"90%", margin:"auto", fontSize:"1.4rem"}}>
                    <h4 className="dialogTitle">{props.text}</h4>
                </div>
            </div>
            {/* <DialogTitle id="alert-dialog-title"  style={{ textAlign: "center" }}>
                <h4 className="dialogTitle">{props.text}</h4>
            </DialogTitle> */}
            <DialogActions style={{ justifyContent: "center" }}>
                <div>
                    <Button
                        variant="contained"
                        color="error"
                        style={{
                            height:"50px",
                            width:"130px",
                            backgroundColor: '#32d65f'
                        }}
                        onClick={hiddenDialog}
                    >Aceptar</Button>
                </div> 
            </DialogActions>
        </Dialog>
    )
}

export default DialogConfirmation;