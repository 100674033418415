import 'Styles/App.scss';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

//Paginas
import ListDocument from 'Pages/UpdateDocument/ListDocument';
import DetailDocument from 'Pages/UpdateDocument/DetailDocument';
import NotFound from 'Components/NotFound';
import Login from 'Pages/Login';

const App = () => {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/update/order/document" element={<ListDocument />} />
                    <Route path="/update/order/document/:id" element={<DetailDocument />} />
                    <Route path="/auth/google/failure" element={<NotFound />} />
                    <Route path="/auth/signin" element={<Login />} />
                    <Route path="" element={<Navigate to="/auth/signin" replace={true} />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
