import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {
    GoogleAuthProvider,
    OAuthProvider,
    getAuth,
    signInWithEmailAndPassword,
    deleteUser,
    signInWithPopup,
    signOut
} from "firebase/auth";

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_APP_ID}`,
    measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
};

export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const auth = getAuth(app);

//Metodo de inicio de sesion de usuario
export const login = async (email: string, password: string) => {
    let user = signInWithEmailAndPassword(auth, email, password).then(async (userCredential: any) => {
        return {
            uid: await userCredential.user.uid,
            auth: true,
            error: false
        }
    }).catch((error) => {
        console.log(error.message)
        return {
            auth: false,
            error: true,
            message: error.message
        }
    })
    return user
}

export const deleteUserGoogle = async () => {
    let user = auth.currentUser
    if ( user !== null ) {
        deleteUser(user).then(() => {
            console.log("User Deleted")
        }).catch((error) => {
            console.log(error.message)
        });
    }
}

export const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    let user = await signInWithPopup(auth, provider).then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (null === credential) {
            return
        }
        const user = result.user;
        return {
            uid: user.uid,
            auth: true,
            error: false
        }
    }).catch((error) => {
        console.log(error.message)
        return {
            auth: false,
            error: true,
            message: error.message
        }
    });
    return user
}

export const loginWithApple = async () => {
    const provider = new OAuthProvider('apple.com');
    let user = await signInWithPopup(auth, provider).then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        if (null === credential) return
        const user = result.user;
        return {
            uid: user.uid,
            auth: true,
            error: false
        }
    }).catch((error) => {
        console.log(error.message)
        return {
            auth: false,
            error: true,
            message: error.message
        }
    });
    console.log(user)
    return user
}


export const logout = async () => {
    let user = signOut(auth).then(() => {
        return {
            auth: false
        }
    }).catch((error) => {
        console.log(error)
        return {
            auth: false,
            error: true,
            message: error.message
        }
    })
    return user
}

export default db;


