import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Grid, IconButton } from "@mui/material";

// Servicios
import UserContextP from "Interfaces/Context";
import { auth, logout } from "Service/Connections";

// Iconos
import DiscountIcon from '@mui/icons-material/Discount';
import LogoutIcon from '@mui/icons-material/Logout';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Loader from "Components/Loader";

const ListDocument = () => {
    const context = useContext(UserContextP)
    const navigate = useNavigate()
    const params = useParams()
    const [errorLogin, setErrorLogin] = useState(false)
    const [errorServer, setErrorServer] = useState(false)
    const [dataOrder, setDataOrder] = useState<any>()
    const [loader, setLoader] = useState(true)

    //Funcion de carga
    useEffect(() => {
        document.title = "Ordenes Nuevas"
        if (context.user.id === "" && context.authenticate.uid === "") {
            auth.onAuthStateChanged(async(response) => {
                if ( response !== null ) {
                    let name:any = response.displayName?.split(" ")
                    context.assignUser(
                        response.uid ?? "",
                        name[1] ?? "",
                        name[0] ?? "",
                        response.email ?? ""
                    )
                    context.assignateAuth(response.uid ?? "", true)
                } else {
                    navigate("/auth/signin")
                }
            })
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (context.user.id !== "" && context.authenticate.uid !== "") getDataOrder()
        // TODO: Implementar el metodo para obtener las ordenes
        // let unSubscribe = () => {
        //     (async () => {
        //         let values = await context.getDocuments$([{
        //             key: "saveOrderSap",
        //             operator: "==",
        //             value: false
        //         }, {
        //             key: "test",
        //             operator: "==",
        //             value: false
        //         }, {
        //             key: "canceled",
        //             operator: "==",
        //             value: false
        //         },{
        //             key: "type",
        //             operator: "in",
        //             value: ["SellerOrder"]
        //         }], { key: "created_at", direction: "desc" })
        //         console.log(values)
        //     })();
        // }
        // return unSubscribe
        // eslint-disable-next-line
    }, [context.user, context.authenticate, context.authenticate.uid])


    //Obtiene la informacion de las ordenes
    const getDataOrder = async () => {
        let data:any = {
            token: context.authenticate.uid,
            typeDocument: params.typeDocument
        }
        await axios.post(`${process.env.REACT_APP_URL_ORDER}/documents/get/pending/verify`, data).then((response) => {
            setDataOrder(response.data.data)
        }).catch((error) => {
            setErrorLogin(() => error.response.status === 403 || error.response.status === 401)
            setErrorServer(() => error.response.status !== 403 && error.response.status !== 401)
        }).finally(()=> setLoader(false))
    }

    //Metodo para navegar al detalle de la orden
    const navigateTo = (item: any) => {
        context.assignateCopyData({ order: item })
        navigate(item.$key)
    }

    const closeSession = async () => {
        context.assignUser("","","","")
        context.assignateAuth("", false)
        await logout()
        navigate("/auth/signin")
    }
    return (
        <div className="App">
            {
                loader ? 
                    <Loader openLoader={loader} inPage={true} redirect={false} />
                :
                errorServer ?
                    <>
                        <ReportOffIcon style={{ fontSize: 250, color: "#ed1307" }} />
                        <p style={{ fontSize: 25 }}>¡Algo salio mal!</p>
                        <p style={{ fontSize: 15 }}>El recurso que esta intentando acceder no esta disponible</p>
                    </>
                    :
                    errorLogin ?
                        <>
                            <NoAccountsIcon style={{ fontSize: 250, color: "#ed1307" }} />
                            <p style={{ fontSize: 25 }}>¡No tienes permisos para acceder a esta seccion!</p>
                            <p style={{ fontSize: 15 }}>Necesita acceder a su cuenta y/o con credenciales suficientes para poder visualizar este contido</p>
                        </>
                        :
                        <>
                            <div style={{ display:"flex", justifyContent:"flex-end", marginTop: "20px", marginRight:"20px"}}>
                                <IconButton onClick={closeSession} >
                                    <LogoutIcon />
                                </IconButton>
                            </div>
                            <img src={require("Media/Logo.png")} alt="Logo premier tools" style={{  marginBottom: "20px" }} />
                            <h1 style={{ color: "#112a56" }}>Órdenes en revisión</h1>
                            <p>Hola {context.user.name}, este es el listado de ordenes pendientes</p>
                            {
                                (dataOrder?.length > 0) ?
                                    dataOrder?.map((item: any, index: number) => {
                                        return (
                                            <div key={index} className="cardOrderUpdate" onClick={() => { navigateTo(item) }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={item.descuento > 0  ? 7 : 12}>
                                                        <div style={{ display:"flex", gap:"10px", marginLeft: "20px", marginTop: "30px" }}>
                                                            { item.type === "PurchaseOrder" && <ArchiveIcon style={{ color:'#bf700f'}} />}
                                                            { item.type === "SellerOrder" && <UnarchiveIcon style={{ color:'#248c4c' }} />}
                                                            <h3 style={{ marginTop:"0px"}}>{item.cardName} - #{ item.numberOrder }</h3>
                                                        </div>
                                                        {item.type === "PurchaseOrder" && <p style={{ marginLeft: "24px", marginTop: 0, marginBottom: '10px' }}>Orden de compra</p>}
                                                        {item.type === "SellerOrder" && <p style={{ marginLeft: "24px", marginTop: 0, marginBottom: '10px'  }}>Orden de venta</p>}
                                                    </Grid>
                                                    <Grid item xs={item.descuento > 0  ? 4 : 0}>
                                                        <div style={{ textAlign: "left", marginTop: "30px" }}>
                                                            {
                                                                item.descuento > 0 &&
                                                                <div style={{ display: "flex", gap: "3px", justifyContent: "end", marginLeft: "20px" }}>
                                                                    <DiscountIcon sx={{ color: "#00cc00" }} />
                                                                    <p style={{ marginTop: "-3px", color: "#00cc00", fontWeight: "bold" }}>Órden con descuento</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={item?.authorized?.length > 0 ? 7 : 11}>
                                                        <div style={{ marginLeft: "20px" }}>
                                                            <p style={{ marginTop: "-10px" }}>{ typeof item.payToCode == "string" ? item.payToCode : "-" }</p>
                                                            <p style={{ marginTop: "-10px", fontSize: "90%" }}>{item.typeDocument === "COF" ? "Consumidor Final" : "Credito Fiscal"}</p>
                                                            <p style={{ marginTop: "-10px", fontSize: "90%" }}>Total: ${item.total?.toFixed(2)}</p>
                                                            <p style={{ marginTop: "-10px", fontSize: "90%" }}>Solicitada por: {item.vendorName}</p>
                                                            <p style={{ marginTop: "-10px", fontSize: "90%" }}>Hora de la orden: {item.date}</p>

                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={item?.authorized?.length > 0 ? 4 : 0} style={{ display:"flex", justifyContent:"flex-end"}}>
                                                        {
                                                            item?.authorized?.length > 0 &&
                                                                <p style={{ marginTop: "-10px", color: "#00cc00", fontWeight: "bold" }}>{item?.authorized[0]?.userId === context.user.id && "Esta orden ya la autorizo"}</p>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    })
                                    :
                                    <div>
                                        <RequestPageIcon style={{ color: "#fc9803", fontSize: 90 }} />
                                        <h2 style={{ marginTop: "-10px" }}>No tiene ordenes pendientes</h2>
                                    </div>
                            }
                            <br/>
                            <br/>
                            <br/>
                        </>
            }
        </div>
    )
}

export default ListDocument;