import { useState } from "react";
import { Authentication, User } from "Interfaces/User";
import UserContextP, { FiltersInterface, OrderInterface, UserContextModel } from "Interfaces/Context";
import axios from "axios";
import { collection, limit, onSnapshot, orderBy, query, QueryFieldFilterConstraint, where } from "firebase/firestore";
import db from '../Service/Connections'; // Adjust the import according to your project structure

interface Props {
    children: React.ReactNode;
}
const UserContextProvider = (props:Props) => {
    //Informacion base del usuario
    const [user, setUser] = useState<User>({
        id: "",
        name: "",
        email: "",
        lastName: ""
    });

    //Informacion de autenticacion
    const [authenticate, setAuth] = useState<Authentication>({
        auth: false,
        uid: ''
    })

    //Copia de la informacion
    const [dataCopy, setDataCopy]= useState<any>()
   
    //Assigna un usuario
    const assignUser = (id: string, lastName: string, name: string, email: string) => {
        const newUser: User = {
            id: id,
            name: name,
            lastName: lastName,
            email: email
        }
        setUser(newUser)
    }

    //Asigna el estado del usuario
    const assignateAuth = (uid: string, authenticate: boolean) => {
        let authContent: Authentication = {
            auth: authenticate,
            uid: uid
        }
        setAuth(authContent)
    }

    //Copiado de la informacion
    const assignateCopyData = ( data:any ) =>{
        setDataCopy(data)
    }

    const navigateToSegment = async( navigate:any ) => {
        let data = {
            token: authenticate.uid,
            include: ["documentsAuthorized"]
        }

        await axios.post(`${process.env.REACT_APP_URL_ORDER}/settings/app/info/detail`,data).then((response)=>{
            let data = response.data.data
            if ( data.hasOwnProperty("authorizedOrderBuyer") && data.hasOwnProperty("authorizedOrderSeller") ) {
                if ( data.authorizedOrderSeller.includes(user.email) ) navigate("/update/order/stonks")
                else if (data.authorizedOrderBuyer.includes(user.email) ) navigate("/update/order/sold")
                else navigate("/auth/google/failure")
            }
        }).catch((error)=>{
            console.log(error.message)
        })
    }

    const getDocuments$ = async(filters: FiltersInterface[], orderType?: OrderInterface, limitValue?: number) => {
        return new Promise<any[]>((resolve, reject) => {
            let collectionRef = collection(db, "orders");
            let whereFilters: QueryFieldFilterConstraint[] = [];
            filters.forEach((filter) => whereFilters.push(where(filter.key, filter.operator, filter.value)));
            let q = query(collectionRef, ...whereFilters);

            if (orderType) q = query(q, orderBy(orderType.key, orderType.direction));
            if (limitValue) q = query(q, limit(limitValue));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let documents: any[] = [];
                querySnapshot.forEach((doc) => {
                    documents.push({ id: doc.id, ...doc.data() });
                });
                resolve(documents);
                // Maneja los datos de los documentos según sea necesario
            }, (error) => {
                reject([]);
            });

            // Limpia el listener al desmontar el componente
            return () => unsubscribe();
        });
    }

    //Crea el contexto en forma de etiqueta
    const usercontext: UserContextModel = {
        user,
        assignUser,
        dataCopy,
        assignateCopyData,
        authenticate,
        assignateAuth,
        getDocuments$,
        navigateToSegment
    }

    return (
        <UserContextP.Provider value={usercontext} >
            {props.children }
        </UserContextP.Provider>
    );
};

export default UserContextProvider;