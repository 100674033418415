import { useState, useEffect, useContext } from "react";
import {
    Button,
    Grid,
    IconButton
} from "@mui/material";

//Estilo
import "Styles/App.scss"
import UserContextP from "Interfaces/Context";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";
import Loader from "Components/Loader";
import DialogConfirmation from "Components/DialogConfirmation";
import { CssTextField } from "Components/CssTextField";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import TableProducts from "Components/DetailDocument/TableProducts";
import Header from "Components/DetailDocument/Header";
import InformationDocument from "Components/DetailDocument/InformationDocument";
import ClientHistory from "Components/DetailDocument/ClientHistory";

const DetailDocument: React.FC = () => {
    const context = useContext(UserContextP)
    const navigate = useNavigate()
    // Autorizacion de pedidos
    const [loader, setLoader] = useState(false)
    const [order, setOrder] = useState<any>()
    const [showDialog, setshowDialog] = useState(false)
    const [message, setMessage] = useState({
        message: "",
        type: "",
        status: 0,
        data: [],
        title: ""
    })
    const [text, setText] = useState("")
    const [discountDinner, setDiscountDinner] = useState(0)
    // Obtencion de la data
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [listBills, setListBills] = useState<any>([])

    useEffect(() => {
        document.title = "Actualizar estado"
        window?.scrollTo(0, 0);
        setOrder(context?.dataCopy?.order)

        if (context?.dataCopy?.order !== undefined && context?.dataCopy?.order?.descuento !== undefined && context?.dataCopy?.order?.total !== undefined) {
            let discountD = context?.dataCopy?.order?.totalLines * Math.round(((context?.dataCopy?.order?.descuento) / 100) * 100) / 100
            discountD = Math.round((discountD) * 100) / 100
            setDiscountDinner(discountD)
        }
        if (context?.dataCopy?.order?.cardCode !== undefined) getListBills()
        else  navigate(-1)
        //eslint-disable-next-line
    }, [])

    /// Cambio de estado de la orden, y la guarda en el sistem
    const changeStatusOrder = async (type: boolean) => {
        let data = {
            token: context.authenticate.uid,
            data: {
                state: type,
                id: context.dataCopy.order.$key,
                notes: text
            }
        }
        setLoader(true)

        await axios.post(`${process.env.REACT_APP_URL_ORDER}/documents/update/pending/status`, data).then((response) => {
            setMessage(response.data)
            setLoader(false)
            setshowDialog(true)

        }).catch((error) => {
            console.log(error.response.data)
            setMessage(error.response.data)
            setLoader(false)

        })
    }

    // Cambio de color de fondo
    const assiganteBackgroundColor = (): string => context?.dataCopy?.order?.type && context?.dataCopy?.order?.type === 'PurchaseOrder' ? '#79AC78' : '#173456'

    // Obtiene el listado de facturas
    const getListBills = async () => {
        let body = {
            "token": context.user.id,
            "data": { 
                "cardCode": context.dataCopy?.order?.cardCode ?? "",
                "typeDocument":  context?.dataCopy?.order?.type && context?.dataCopy?.order?.type === 'PurchaseOrder' ? 4 : 2
            }
        }
        await axios.post(`${process.env.REACT_APP_URL_ORDER}/sapdocument/getdocuments/history/costumer`, body).then((response) => {
            setListBills(response.data?.data?.expired ?? [])
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setError(true)
        })
    }

    return (
        <>
            <Loader
                openLoader={loader}
                inPage={false}
                redirect={true}
            />
            <DialogConfirmation
                open={showDialog}
                text={message?.message}
                quantity={-1}
            />
            <div className="App tableUpdateOrder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton 
                        onClick={() => navigate(-1)}
                        style={{ 
                            width: "50px",
                            height: "50px",
                            margin:"auto 0",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <h2>Orden número {order?.numberOrder} </h2>
                    <div></div>
                </div>
                {
                    loading ?
                        <Loader
                            openLoader={loading}
                            inPage={true}
                            redirect={false}
                        />
                        :
                        <>
                            <Grid container spacing={1} >
                                <Header
                                    color={assiganteBackgroundColor()}
                                    order={order} />
                                <TableProducts order={order} color={assiganteBackgroundColor()} />
                                <InformationDocument
                                    order={order}
                                    color={assiganteBackgroundColor()}
                                    discountDinner={discountDinner} />
                                {
                                    error ?
                                        <Grid item xs={12} style={{ borderTop: '1px solid #CACACA'}}>
                                            <p>No se logro obtener el historial de facturas de este cliente.</p>
                                        </Grid>
                                        :
                                        <ClientHistory
                                            data={listBills}
                                            color={assiganteBackgroundColor()} />
                                }

                                <div className="table-details-document">
                                    <div className="thBase thHeader item-cell">
                                        <p style={{ fontSize: "80%", marginTop: "-2px" }}>
                                            Tipo de pago
                                        </p>
                                    </div>
                                    <div className="thBase thHeader item-cell">
                                        <p style={{ fontSize: "80%", marginTop: "-3px" }}>
                                            Tipo de
                                            <br />
                                            contribuyente
                                        </p>
                                    </div>
                                    <div className="thBase thHeader item-cell">
                                        <p style={{ fontSize: "90%", marginTop: "-2px" }}>
                                            {
                                                context?.dataCopy?.order?.type && context?.dataCopy?.order?.type === 'PurchaseOrder' ?
                                                    'Comprador'
                                                    :
                                                    'Vendedor'
                                            }
                                        </p>
                                    </div>
                                    <div className="thBase thContent item-cell">
                                        <h4>
                                            {order?.typePayment}
                                        </h4>
                                    </div>
                                    <div className="thBase thContent item-cell">
                                        <h4 style={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}>
                                            {order?.u_type.split("/").join(" / ")}
                                        </h4>
                                    </div>
                                    <div className="thBase thContent item-cell">
                                        <h4>
                                            {order?.vendorName}
                                        </h4>
                                    </div>
                                </div>
                                <Grid item xs={12}>
                                    <CssTextField
                                        placeholder="Notas para el vendedor"
                                        rows={6}
                                        multiline
                                        fullWidth
                                        value={text}
                                        onChange={(e: any) => {
                                            setText(e.target.value)
                                        }}
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "10px"
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        size="small"
                                        fullWidth
                                        variant="contained"
                                        color="error"
                                        endIcon={<CloseIcon sx={{ marginTop: "-4px" }} />}
                                        style={{
                                            backgroundColor: '#ea3232',
                                        }}
                                        onClick={() => { changeStatusOrder(false) }}
                                    ><p style={{ fontWeight: "600" }}>Rechazar</p></Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        endIcon={<CheckIcon sx={{ marginTop: "-4px" }} />}
                                        style={{
                                            backgroundColor: '#32d65f',
                                            minHeight: "50px",
                                        }}
                                        onClick={() => {
                                            changeStatusOrder(true)
                                        }}
                                    ><p style={{ fontWeight: "600" }}>Aprobar</p></Button>
                                </Grid>
                            </Grid>
                        </>
                }
            </div>
        </>
    )
}

export default DetailDocument;