import { Grid } from "@mui/material"

interface PropsInterface {
    order:any
    color:string
}

const Header = ( props: PropsInterface ) => {
    const { color, order } = props
    return (
        <>
            <Grid item xs={6} sx={{ border: "1px solid #000" }}>
                <div className="thBase thHeader">
                    <p>
                        Razón social
                    </p>
                </div>
            </Grid>
            {/* Nombre Comercial */}
            <Grid item xs={6}  sx={{ border: "1px solid #000" }}>
                <div className="thBase thHeader">
                    <p>
                        Nombre Comercial
                    </p>
                </div>
            </Grid>
            <Grid item xs={6} sx={{ border: "1px solid #000" }}>
                <div className="thBase thContent">
                    <h4>
                        {order?.cardName}
                    </h4>
                </div>
            </Grid>
            <Grid item xs={6}  sx={{ border: "1px solid #000" }}>
                <div className="thBase thContent">
                    <h4>
                        {order?.cardFName}
                    </h4>
                </div>
            </Grid>
            {/* Direccion de envio  */}
            <Grid item xs={12}  sx={{ border: "1px solid #000" }}>
                <div className="thBase thHeader">
                    <p>
                        Dirección
                    </p>
                </div>
            </Grid>
            <Grid item xs={12}  sx={{ border: "1px solid #000" }}>
                <div className="thBase thContent">
                    <h4>
                        {order?.street !== '' ? order?.street : 'No posee dirección'}
                    </h4>
                </div>
            </Grid>
        </>
    )
}

export default Header