import { Grid } from "@mui/material"

interface PropsInterface {
    order:any
    color:string
    discountDinner:number
}

const InformationDocument = ( props: PropsInterface ) => {
    const { order, color, discountDinner } = props
    return (
        <>
            <Grid className="item-cell" item xs={12}>
                <div className="thBase thHeader">
                    <p>
                        Información del documento
                    </p>
                </div>
            </Grid>
            <Grid className="item-cell" item xs={6} >
                <div className="thBase thContent">
                    <h4>Subtotal</h4>
                </div>
            </Grid>
            <Grid className="item-cell" item xs={6}  style={{ textAlign:"right"}}>
                <div className=" thBase thContent item-details">
                    <h4>$ {order?.totalLines.toFixed(2)}</h4>
                </div>
            </Grid>
            <Grid className="item-cell" item xs={6}>
                <div className=" thBase thContent item-details">
                    <h4>IVA</h4>
                </div>
            </Grid>
            <Grid className="item-cell" item xs={6} style={{ textAlign:"right"}}>
                <div className="thBase thContent item-details">
                    <h4>$ {(order?.total * 0.13).toFixed(2)}</h4>
                </div>
            </Grid>
            <Grid className="item-cell" item xs={6}>
                <div className="thBase thContent">
                    <h4>Total del documento</h4>
                </div>
            </Grid>

            <Grid className="item-cell" item xs={6} style={{ textAlign:"right"}}>
                <div className="thBase thContent item-details">
                    <h4>$ {(parseFloat((order?.total * 0.13).toFixed(2)) + order?.total).toFixed(2)}</h4>
                </div>
            </Grid>
        </>
    )
}

export default InformationDocument