import { Grid } from "@mui/material"

interface PropsInterface {
    color:string
    data: any[]
}

const ClientHistory = ( props: PropsInterface ) => {
    const { color, data } = props
    return (
        <>
            <div className="thBase thHeader item-cell" >
                <p>
                    Record del cliente <br/> (facturas expiradas)
                </p>
            </div>
                {
                    data?.map((item: any, index: number) => {
                        return (
                            <div key={index}className="bills-content-list">
                                    <div className="content">
                                        <div className="item-cell item-bill">
                                            <h4>
                                                Num.
                                                <br/>
                                            </h4>
                                            <p>{item.DocNum?.toString().substring(4) ?? ''}</p>                                                
                                        </div>
                                        <div className="item-cell item-bill">
                                            <h4>
                                                Fecha
                                                <br/>
                                            </h4>
                                            <p>{item.DocDate ?? ''}</p>
                                        </div>
                                        <div className="item-cell item-bill">
                                            <h4>
                                                Caducidad
                                            </h4>
                                            <p>{item.DocDueDate ?? ''}</p>
                                        </div>
                                        <div className="item-cell item-bill">
                                            <h4>Tipo de pago</h4>
                                            <p>{item.U_TipoPago ?? ''}</p>
                                        </div>
                                        <div className="item-cell item-bill" style={{textAlign:"right", paddingRight:"5px"}} >
                                            <h4>
                                                Pagado / Total
                                                <br/>
                                            </h4>
                                            <p>${item.PaidToDate?.toFixed(2) ?? '0.00'} / ${item.DocTotal?.toFixed(2) ?? '0.00'}</p>
                                        </div>
                                    </div>
                            </div>
                        )
                    })
                }
        </>
    )
}

export default ClientHistory