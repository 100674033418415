import { Divider, Grid } from "@mui/material"


interface PropsInterface {
    order: any
    color: string
}

const TableProducts = (props: PropsInterface) => {
    const { order, color } = props
    const priceProduct = (price: number, cantidad: number):number => {
        return Math.round(((price * cantidad))*100)/100
    }
    return (
        <>
            {/* Listado de productos */}
            <Grid item xs={12} sx={{ border: "1px solid #000" }}>
                <div className="thBase thHeader">
                    <p>
                        Productos
                    </p>
                </div>
            </Grid>
            <div className="table-products" style={{ alignItems:"center"}}>
                <div className="item-cell" style={{ display:"flex", justifyContent:"center", minHeight:"110px", alignItems:"center" }} >
                    <p>Cod.</p>
                </div>
                <div className="item-cell" style={{ display:"flex", justifyContent:"center", minHeight:"110px", alignItems:"center" }}>
                    <p>Cant.</p>
                </div>
                <div className="item-cell" style={{ display:"flex", justifyContent:"center", minHeight:"110px", alignItems:"center" }}>
                    <p>Desc.</p>
                </div>
                <div className="item-cell" style={{ display:"flex", justifyContent:"center", minHeight:"110px", alignItems:"center" }}>
                    <p>Precio unitario <br />sin iva</p>
                </div>
                <div className="item-cell" style={{ display:"flex", justifyContent:"center", minHeight:"110px", alignItems:"center" }}>
                    <p>Precio de linea<br />sin iva</p>
                </div>
                <div className="item-cell" style={{ display:"flex", justifyContent:"center", minHeight:"110px", alignItems:"center" }}>
                    <p>Precio de linea<br />con iva</p>
                </div>
            </div>
            {
                order?.products?.map((item: any, index: number) => {
                    return (
                        <div key={index} className="table-products">
                            <div className="thItemProduct item-cell">
                                <p>{item.id}</p>
                            </div>
                            <div className="thItemProduct item-cell"  style={{ justifyContent:"center"}}>
                                <p>{item.cantidad}</p>
                            </div>
                            <div className="thItemProduct item-cell" style={{ fontSize:"0.96rem", justifyContent:"flex-start", textIndent:"10px"}}>
                                <p>{item.ItemName}</p>
                            </div>
                            <div className="thItemProduct item-cell" style={{ justifyContent:"flex-end", paddingRight:"4px"}}>
                                <p>${item.price.toFixed(2)}</p>
                            </div>
                            <div className="thItemProduct item-cell" style={{ justifyContent:"flex-end", paddingRight:"4px"}}>
                                <p>${priceProduct(item.cantidad, item.price).toFixed(2)}</p>
                            </div>
                            <div className="thItemProduct item-cell" style={{ justifyContent:"flex-end", paddingRight:"4px"}}>
                                <p>${(Math.round((priceProduct(item.cantidad, item.price) * 1.13)* 100)/100).toFixed(2)}</p>
                            </div>
                            <Divider />
                        </div>
                    )
                })
            }
        </>
    )
}


export default TableProducts